/* You can add global styles to this file, and also import other style files */
@import 'theme.scss';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.title,
.subtitle,
.horizontal-menu,
mat-card-title {
  font-family: Raleway, "Helvetica Neue", sans-serif;
}

// GLOBAL VARS
:root {
  --headerBackground: url('https://firebasestorage.googleapis.com/v0/b/hallo-bob.appspot.com/o/images%2FbobBackground.jpg?alt=media&token=a8a89b33-247f-475f-929d-3f238e8c4d94');
  --primary: #3470ca;
  ;
  --accent: #cddc39;
  --loginImage: url('https://firebasestorage.googleapis.com/v0/b/hallo-bob.appspot.com/o/images%2FBob_de_Bot.png?alt=media&token=c955a823-42d1-40d5-8e5f-f27de53d6670');
  --loginVideo: url('https://firebasestorage.googleapis.com/v0/b/hallo-bob.appspot.com/o/background%20%2FFixed_Bob_de_Bot_banner.mp4?alt=media&token=b0034d0c-e8ad-41d8-b541-c1f77a9d7c29');
}

.expension {
  padding-bottom: 20px;
}

.outline {
  border: 1px solid #E0E0E0;
  box-shadow: none !important;
}

.app-full-screen-dialog .mat-dialog-container {
  padding: 0 !important;
  border-radius: 0 !important;
}

/* WebKit and Blink */
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

@media (max-width: 599px) {
  .page {
    margin-bottom: 56px;
  }
}

@media print {
  .mat-sidenav-content {
    background-color: white;
  }

  .bottom-navigation {
    visibility: hidden;
  }
}
